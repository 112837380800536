"use client";
import "react-toastify/dist/ReactToastify.css";
import Script from "next/script";

const Layout = ({ children }: any) => {
  return (
    <main className={`h-screen bg-default bg-cover`}>
      <div
        className={`h-full sm:py-8 sm:max-w-lg mx-auto bg-default bg-cover bg-center bg-no-repeat`}
      >
        <div
          className={
            "bg-white h-full w-full relative sm:rounded-xl shadow-md overflow-x-hidden overflow-y-auto scroll scroll1"
          }
        >
          {children}
        </div>
      </div>
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-KQRDZZJP6V"
      />
      <Script async src="/scripts/googleAnalyticsScript.js" />
    </main>
  );
};

export default Layout;
